<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <img src="../../assets/logo.png" />
          <div class="text-center text-muted mb-4">
            <small>{{ $t("login.sign in with credentials") }}</small>
          </div>
          <form role="form" class="login-form">
            <div
              class="bg-danger text-white pl-3 mx-4 mt-2 py-1 live-error text-center"
              v-if="error"
            >
              {{ error }}
            </div>
            <base-input
              formClasses="input-group-alternative mb-3"
              :placeholder="$t('form.email')"
              addon-left-icon="ni ni-email-83"
              v-model="email"
            >
            </base-input>
            <div
              v-show="emailValidationErr"
              class="live-error bg-danger text-white mb-2"
            >
              {{ $t("validation.email not valid") }}
            </div>

            <base-input
              formClasses="input-group-alternative mb-3"
              :placeholder="$t('form.password')"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
            >
            </base-input>
            <div
              v-show="passwordValidationErr"
              class="live-error bg-danger text-white mb-2"
            >
              {{ $t("validation.password must 6") }}
            </div>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                :disabled="!isValidForm"
                @click.prevent="login()"
                >{{ $t("login.sign in") }}</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link :to="`/forget-password`" class="text-light"
            ><small>{{ $t("login.foreget password") }}</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      error: false,
      success: false,
    };
  },
  computed: {
    emailValidationErr() {
      return (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        ) && this.email.length > 0
      );
    },
    passwordValidationErr() {
      return this.password.length > 0 && this.password.length < 6;
    },
    isValidForm() {
      return (
        this.password.length > 5 &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        ) &&
        this.email.length > 0
      );
    },
  },
  methods: {
    login() {
      let { email, password } = this;
      this.$store.dispatch("Login", { email, password }).then((path) => {
        this.error = this.$store.getters.isError;
        this.$router.push(path);
      });
    },
  },
};
</script>
<style scoped>
.shadow {
  box-shadow: 6px 6px 6px rgb(0, 0, 0, 0.2) !important;
}
img {
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
  margin-bottom: 25px;
  border-radius: 50%;
}
</style>
